import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, ReplaySubject, of, throwError } from 'rxjs';
import { map, catchError, tap, retryWhen, timeout, retry } from 'rxjs/operators';

import * as _ from 'lodash';
interface HeaderAuth {
  key: string;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  public processStream = new ReplaySubject<boolean>();
  public AppAuth!: HeaderAuth;
  public UserAuth!: HeaderAuth;
  public XApiKey!: HeaderAuth;
  public MSGKey!: HeaderAuth;
  public customHeaders!: Array<{ key: string; value: string }>;

  constructor(private http: HttpClient) { }

  private getHttpHeaders() {
    let httpHeaders: HttpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Accept', 'application/json');
    httpHeaders = httpHeaders.append('Content-Type', 'application/json');
    if (
      typeof this.AppAuth !== 'undefined' &&
      this.AppAuth.key !== '' &&
      this.AppAuth.value !== ''
    ) {
      httpHeaders = httpHeaders.append(this.AppAuth.key, this.AppAuth.value);
    }
    if (
      typeof this.XApiKey !== 'undefined' &&
      this.XApiKey.key !== '' &&
      this.XApiKey.value !== ''
    ) {
      httpHeaders = httpHeaders.append(this.XApiKey.key, this.XApiKey.value);
    }
    if (
      typeof this.MSGKey !== 'undefined' &&
      this.MSGKey.key !== '' &&
      this.MSGKey.value !== ''
    ) {
      httpHeaders = httpHeaders.append(this.MSGKey.key, this.MSGKey.value);
    }
    return httpHeaders;
  }

  private getHttpHeadersForm() {
    const httpHeaders: HttpHeaders = new HttpHeaders();
    return httpHeaders;
  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }
  get(route: any, params?: any): Observable<any> {
    this.processStream.next(true);
    return this.http
      .get(route, {
        params,
        headers: this.getHttpHeaders(),
      })
      .pipe(
        retryWhen(this.handleRety),
        tap(this.handleTap<any>('getData')),
        map(this.extractData)
      );
  }
  getResponses(endpoint: any, route: any, params?: any): Observable<any> {
    this.processStream.next(true);
    return this.http
      .get(endpoint + route, {
        params,
        headers: this.getHttpHeaders(),
      })
      .pipe(
        retryWhen(this.handleRety),
        tap(this.handleTap<any>('getResponses')),
        map(this.extractData)
      );
  }

  getResponse(endpoint: any, route: any, id: any): Observable<any> {
    this.processStream.next(true);
    return this.http
      .get(endpoint + route + id)
      .pipe(
        retryWhen(this.handleRety),
        tap(this.handleTap<any>('getResponse')),
        map(this.extractData)
      );
  }

  postResponse(URL: any, dataSet: any, params?: HttpParams): Observable<any> {
    this.processStream.next(true);
    return this.http
      .post<any>(URL, JSON.stringify(dataSet), {
        params,
        headers: this.getHttpHeaders(),
      })
      .pipe(
        retryWhen(this.handleRety),
        tap(this.handleTap<any>('addResponses')),
        catchError(this.handleError<any>('addResponse'))
      );
  }

  postFormDataResponse(URL: any, formData: any, params?: HttpParams): Observable<any> {
    this.processStream.next(true);
    return this.http
      .post<any>(URL, formData, {
        params,
      })
      .pipe(
        retryWhen(this.handleRety),
        tap(this.handleTap<any>('addResponses')),
        catchError(this.handleError<any>('addResponse'))
      );
  }

  addResponse(endpoint: any, route: any, dataSet: any, params?: HttpParams): Observable<any> {
    this.processStream.next(true);
    return this.http
      .post<any>(endpoint + route, JSON.stringify(dataSet), {
        params,
        headers: this.getHttpHeaders(),
      })
      .pipe(
        retry(3), // retry up to 3 times
        tap(this.handleTap<any>('addResponses')),
        catchError(this.handleError<any>('addResponse'))
      );
  }

  addResponse2(endpoint: any, route: any, dataSet: any, params?: HttpParams): Observable<any> {
    this.processStream.next(true);
    return this.http.post<any>(endpoint + route, JSON.stringify(dataSet), {
      params,
      headers: this.getHttpHeaders(),
    });
  }

  sendForm(endpoint: any, route: any, file: any): Observable<any> {
    this.processStream.next(true);
    const url = endpoint + route;
    const formData = new FormData();
    formData.append('', file);
    return this.http
      .post<any>(url, formData, {
        headers: this.getHttpHeadersForm(),
      })
      .pipe(
        retryWhen(this.handleRety),
        tap(this.handleTap<any>('addResponses')),
        catchError(this.handleError<any>('addResponse'))
      );
  }

  updateResponse(endpoint: any, id: any, Response: any, params?: HttpParams): Observable<any> {
    this.processStream.next(true);
    return this.http
      .put(endpoint + '/Responses/' + id, JSON.stringify(Response), {
        params,
        headers: this.getHttpHeaders(),
      })
      .pipe(
        retryWhen(this.handleRety),
        tap(this.handleTap<any>('updateResponse')),
        catchError(this.handleError<any>('updateResponse'))
      );
  }

  updateResponse2(
    endpoint: any,
    route: any,
    dataSet: any,
    params?: HttpParams
  ): Observable<any> {
    this.processStream.next(true);
    return this.http.put<any>(endpoint + route, JSON.stringify(dataSet), {
      params,
      headers: this.getHttpHeaders(),
    });
  }

  deleteResponse(endpoint: any, id: any, params?: HttpParams): Observable<any> {

    this.processStream.next(true);
    return this.http
      .delete<any>(endpoint + id, {
        params,
        headers: this.getHttpHeaders(),
      })
      .pipe(
        retryWhen(this.handleRety),
        tap(this.handleTap<any>('deleteResponse')),
        catchError(this.handleError<any>('deleteResponse'))
      );
  }

  deleteResponse2(endpoint: any, id: any, params?: HttpParams): Observable<any> {

    console.log("DATA BODY: ", id);
    console.log("ENDPOINT: ", endpoint);

    this.processStream.next(true);
    return this.http
      .delete<any>(endpoint, {
        body: id,
        params,
        headers: this.getHttpHeaders(),
      })
      .pipe(
        retryWhen(this.handleRety),
        tap(this.handleTap<any>('deleteResponse2')),
        catchError(this.handleError<any>('deleteResponse2'))
      );
  }

  private handleTap<T>(operation = 'operation', results?: T) {
    return (result: any): Observable<T> => {
        console.log(`${operation} success: ${_.keys(result)}`);
      this.processStream.next(false);
      return of(results as T);
    };
  }
  private handleRety<T>(errors: any): Observable<T> {
    try {
      this.processStream.next(true);
      return (
        errors
          // .mergeMap((error) => (error.status === 429) ? Observable.throw(error) : Observable.of(error))
          .mergeMap((error: any) => throwError(error))
          .delay(2000)
          .take(2)
      );
    } catch (error) {
      return of(errors as T);
    }
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.processStream.next(false);
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}