import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpService } from './https.service';
import { v4 } from 'uuid'; 
import * as SBD from 'sbd';
import * as _ from 'lodash';

export class Message {
  constructor(public author: string, public content: string) { }
}
// Create a new service class
@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private readonly uuid;
  private sessionId: string = "";
  public conversation = new Subject<string>();
  // private readonly endpoint = "https://api.jlmconsulting.services";

  private readonly endpoint = "https://admin.company-tree.com";
  messageMap = {
    "defaultmsg": "I can't understand your text. Can you please repeat"
  }
 
  constructor(private httpService: HttpService) {
    this.uuid =  v4()
  }

  findBye(text: string) {
    // Use a case-insensitive regular expression to match the word "bye"
    const regex = /\bbye\b/i;
    return regex.test(text);
  }
  async getBotAnswer(request: string, filename:string) {
    const botMessage = await this.postToChatGPT(request, filename);
    this.conversation.next(botMessage)
  }
  separateSentences(paragraph: string): string[] {
    return SBD.sentences(paragraph);
  }

  // async getChatGPT(prompt: string) {
  //   const resp = await this.httpService.get(this.endpoint + '/postChatGPT', { prompt, sessionId: this.sessionId }).toPromise();
  //  console.log({resp})
  //   if (_.has(resp, "message") && resp.message.length) {
  //     if (this.findBye(resp.message)) {
  //       this.sessionId = "";
  //     }
  //     return resp.message;
  //   }
  //   return this.messageMap['defaultmsg']
  // }

  async postToChatGPT(request: string, filename:string) {
    request = request.replace(/"/g, "'");
    const resp = await this.httpService.addResponse(this.endpoint, '/postChatGPT', { request, filename }).toPromise();
    if (_.has(resp, "message") && resp.message.length) {
      this.sessionId = resp.sessionId || v4();
      return resp.message;
    }
    return null
  }
}