
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class UploadService {
    private httpOptions = {
        headers: new HttpHeaders({})
        }
    // private endpoint = "https://dev-images.mysm.info/resize";
    // private endpoint = "https://sls-wus2-dev-webhook-demo.azurewebsites.net/createImage";
    // private endpoint = "https://dev-api.company-tree.com/postFile";
    private endpoint = "https://api.company-tree.com/postFile";
    constructor(private http: HttpClient) {

    }
    public async uploadData(file: File): Promise<Observable<Object>> {
        const uploadData = new FormData();
        uploadData.append('file', file);
        const filename = "?filename="+file.name
        console.info({filename});
        // return this.http.post(this.endpoint + filename, uploadData, this.httpOptions).subscribe((res: any) => {
        //     // let body = res.json();
        //     console.log(res)
        //   })
        return this.http.post(this.endpoint + filename, uploadData, this.httpOptions);
    }
}
