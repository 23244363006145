import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/chat' },
  { path: '', loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomeModule) },
  { path: 'pdf', loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatModule) },
  { path: 'home', loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomeModule) },
  { path: 'image', loadChildren: () => import('./pages/image-extract/image.module').then(m => m.ImageModule) },
  { path: 'word', loadChildren: () => import('./pages/word-extract/word-extract.module').then(m => m.WordExtractModule) },
  { path: 'txt', loadChildren: () => import('./pages/txt-extract/txt-extract.module').then(m => m.TxtExtractModule) },
  { path: 'demo', loadChildren: () => import('./pages/demo-arch/demo-arch.module').then(m => m.DemoArchModule) },
  { path: 'enterprise', loadChildren: () => import('./pages/enterprise-arch/enterprise-arch.module').then(m => m.EnterpriseArchModule) },
  { path: '**', loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomeModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
