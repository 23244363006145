<nz-layout class="app-layout">
  <nz-sider class="menu-sidebar"
            nzCollapsible
            nzWidth="256px"
            nzBreakpoint="md"
            [(nzCollapsed)]="isCollapsed"
            [nzTrigger]="null">
    <div class="sidebar-logo">
      <img src="https://serverless-secure-images.s3.ap-southeast-1.amazonaws.com/sls_1695813582613JLM_logo.png" alt="logo">
      <h1>Real-Time Document Extraction</h1>
    </div>
    <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <li nz-submenu nzOpen nzTitle="Dashboard" nzIcon="dashboard">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/home">Upload File</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/pdf">PDF Extraction</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/word">Word Extraction</a>
          </li>
           <li nz-menu-item nzMatchRouter>
            <a routerLink="/image">Image Extraction</a>
          </li>
        </ul>
      </li>
      <li nz-submenu nzOpen nzTitle="Documentation" nzIcon="form">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/demo">Demo Architecture</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/enterprise">Enterprise Architecture</a>
          </li>
        </ul>
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
            <span class="trigger"
               nz-icon
               [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
            ></span>
        </span>
      </div>
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
